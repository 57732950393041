<template>
<div>
    <div class="header_message">
      <span>更新周期：每24小时，每天早上10点前</span
      ><span class="updata_times">上次更新：{{ updataTime }}</span>
    </div>
    <v-table ref="table" :columns="columns" :remote="remote" :row-key="(record, index) => index" :pagination="pagination" @sendTime='sendTime'>

        <template #author="{row}">
            <polymers-video :value="row" :dataType="$root.dataType"></polymers-video>
        </template>

        <template #operation="{row}">
            <a type="link" @click="openVideo(row.shareUrl)">播放视频</a>
        </template>
    </v-table>
</div>
</template>

<script>
import PolymersVideo from "@/components/widgets/polymers-video"

export default {
    components: {
        PolymersVideo
    },
    data() {
        return {
            loading: false,
            visible: false,
            preview_src: '',
            pagination: { pageSize: 10 },
            updataTime:'',
        }
    },
    computed: {
        columns() {
            return [
                {title: "基本信息", dataIndex: "author", scopedSlots: {customRender: 'author'}, ellipsis: true },
                {title: "点赞数", dataIndex: "diggCount", align: 'center', customRender: this.$dict.humanReadable, width: 80},
                {title: "评论数", dataIndex: "commentCount", align: 'center', customRender: this.$dict.humanReadable, width: 80},
                {title: "播放量", dataIndex: "playCount", align: 'center', customRender: this.$dict.humanReadable, width: 80},
                {title: "热度值", dataIndex: "hotValue", align: 'center', customRender: this.$dict.humanReadable, width: 80},
                {title: "热词", dataIndex: "hotWords", align: 'center', width: 150},
                {title: "操作", align: 'center', scopedSlots: {customRender: "operation"}, width: 150}
            ]
        },
    },
    mounted() {
        this.$on("init", this.init);
    },
    methods: {
        openVideo(url) {
            window.open(url)
        },
        init(options = {}) {
            this.$nextTick(() => {
                this.$refs.table.load();
            })
        },
        remote(qp) {
            // console.log(qp)
            return this.$api.core.Polymers.video(qp);
        },
        sendTime(value){
            this.updataTime = value
        }

    }
}
</script>
<style lang="less" scoped>
.header_message {
  font-size: 15px;
  font-weight: 500;
  margin: 8px 0;
  text-align: right;
}
.updata_times {
  display: inline-block;
  margin: 0 20px;
}
</style>
